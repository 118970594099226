@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.about {
    background: lighten($gray-dark, 5%);

    img {
        margin-bottom: rem-calc(24);

        @include mdAndUp {
            margin-bottom: 0;
        }
    }
}