
h2 {
    color: $primary;
    font-size: rem-calc(24);
    font-weight: bold;
}

h3 {
    color: $secondary;
    font-size: rem-calc(22);
    font-weight: bold;
}

p {
    font-size: rem-calc(16);
    font-weight: normal;
    line-height: rem-calc(32);

    @include mdAndUp {
        font-size: rem-calc(20);
        line-height: rem-calc(40);
    }

    &.lead {
        color: $secondary;
        font-size: rem-calc(20);
        font-weight: bold;
        text-align: center;

        @include mdAndUp {
            font-size: rem-calc(26);
        }
    }
}