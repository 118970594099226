@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.nav {

    .btn-donate {
        background: $success !important;
        border-radius: rem-calc(48) !important;
        color: $light !important;
        margin-left: rem-calc(8);
        transition: background 0.5s ease;

        .svg-inline--fa {
            margin-right: rem-calc(8);
        }

        &:hover,
        &:focus {
            background: darken($success, 10%) !important;
        }
    }
}