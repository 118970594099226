@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.btn {
    border-radius: rem-calc(40) !important;
    color: $white !important;
    font-size: rem-calc(18) !important;
    font-weight: bold !important;
    margin-right: rem-calc(12);
    padding: rem-calc(12) rem-calc(24) !important;

    &:last-of-type {
        margin-right: 0;
    }

    .svg-inline--fa {
        margin-right: rem-calc(8);
    }
}

.btn-twitter {
    background: $twitter !important;

    &:hover,
    &:focus {
        background: darken($twitter, 5%) !important;
    }
}

.btn-instagram {
    background: $instagram !important;

    &:hover,
    &:focus {
        background: darken($instagram, 5%) !important;
    }
}

.btn-twitch {
    background: $twitch !important;

    &:hover,
    &:focus {
        background: darken($twitch, 5%) !important;
    }
}

.btn-discord {
    background: $discord !important;

    &:hover,
    &:focus {
        background: darken($discord, 5%) !important;
    }
}