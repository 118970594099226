header {
    background: var(--header-background);
    height: var(--header-height);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    .logo {
        margin-bottom: rem-calc(-32);
    }
}

.header-shim {
    height: var(--header-height);
}
