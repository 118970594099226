body {
    background: var(--background-color) !important;
    color: var(--default-font-color) !important;
    @include default-font(var(--default-font) !important);
    font-size: rem-calc(16);
}

a {
    color: var(--default-link-color);
    text-decoration: none;
    transition: color 0.5s ease;

    &:hover,
    &:focus,
    &:active {
        color: var(--default-link-hover-color);
    }
}

.container-fluid {
    max-width: var(--max-page-width) !important;
    margin-left: auto;
    margin-right: auto;
}

.img-responsive {
    display: flex;
    width: 100%;
}