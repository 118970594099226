/*
BS5 Break Points
---
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px

Usage:
.my-class-name {
    font-size: 14px; // mobile style

    @include tablet {
        font-size: 16px; // Tablet style
    }
}
*/

@mixin smAndBelow {
    @media (max-width: calc($md-min - 1px)) {
        @content;
    }
}

@mixin smAndUp {
    @media (min-width: $sm-min) {
        @content;
    }
}

@mixin smOnly {
    @media (min-width: $sm-min) and (max-width: calc($md-min - 1px)) {
        @content;
    }
}

@mixin mdAndBelow {
    @media (max-width: calc($lg-min - 1px)) {
        @content;
    }
}

@mixin mdAndUp {
    @media (min-width: $md-min) {
        @content;
    }
}

@mixin mdOnly {
    @media (min-width: $md-min) and (max-width: calc($lg-min - 1px)) {
        @content;
    }
}

@mixin lgAndBelow {
    @media (max-width: calc($xl-min - 1px)) {
        @content;
    }
}

@mixin lgAndUp {
    @media (min-width: $lg-min) {
        @content;
    }
}

@mixin lgOnly {
    @media (min-width: $lg-min) and (max-width: calc($xl-min - 1px)) {
        @content;
    }
}

@mixin xlAndBelow {
    @media (max-width: calc($xxl-min - 1px)) {
        @content;
    }
}

@mixin xlOnly {
    @media (min-width: $xl-min) and (max-width: calc($xxl-min - 1px)) {
        @content;
    }
}

@mixin xlAndUp {
    @media (min-width: $xl-min) {
        @content;
    }
}

@mixin xxlAndBelow {
    @media (max-width: calc($xxl-max - 1px)) {
        @content;
    }
}

@mixin xxlAndUp {
    @media (min-width: $xxl-min) {
        @content;
    }
}

@mixin xxlMaxAndUp {
    @media (min-width: $xxl-max) {
        @content;
    }
}

@mixin landscape {
    @media (max-device-width: calc($lg-min - 1px)) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        @content;
    }
}

@mixin touchDevice {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin deviceOnly {
    @media only screen and (min-device-width: 400px) {
        @content;
    }
}

@mixin maxContainerAndUp {
    @media (min-width: calc(#{$container-width} + 32px)) {
        @content;
    }
}