@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.emotes-container {

    .emotes {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: rem-calc(132);

        .emote {
            display: inline-flex;
            padding-left: rem-calc(48);
            padding-right: rem-calc(48);

            img {
                height: rem-calc(132);
            }
        }
    }
}
