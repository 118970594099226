@import './includes/_variables.scss';
@import './includes/_mixins.scss';

:root {
    --background-color: #{$gray-dark};
    --default-font: 'regular';
    --default-font-color: #{$light};
    --default-link-color: #{darken($light, 7%)};
    --default-link-hover-color: #{darken($light, 14%)};

    --header-background: #{$dark};
    --header-min-height: #{rem-calc(75)};

    --max-page-width: #{rem-calc($xxl-max)};
    --header-height: #{rem-calc($header-height)};
}