@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.social-links {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: rem-calc(24);

    .link {
        align-items: center;
        background: $dark;
        border-radius: rem-calc(8);
        display: flex;
        height: rem-calc(40);
        justify-content: center;
        margin-left: rem-calc(8);
        text-decoration: none;
        transition: background 0.5s ease;
        width: rem-calc(40);

        &:hover,
        &:focus {
            background: lighten($dark, 7%);
        }
    }

    .link-twitter {
        color: $twitter;
    }

    .link-twitch {
        color: $twitch;
    }

    .link-instagram {
        color: $instagram;
    }

    .link-discord {
        color: $discord;
    }
}