@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.page-title {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;

    h1 {
        align-items: center;
        color: $secondary;
        display: flex;
        @include default-font('bold');
        font-size: rem-calc(64);
        justify-content: center;
        text-align: center;
    }

    .buttons {

        .btn {
            border-radius: rem-calc(48);
            color: $light !important;
            font-weight: bold;
            padding: rem-calc(16) rem-calc(32);
            transition: background 0.5s ease !important;
        }

        .svg-inline--fa {
            margin-right: rem-calc(8);
        }

        .btn-twitch {
            background: $twitch;

            &:hover,
            &:focus {
                background: darken($twitch, 10%);
            }
        }

        .btn-discord {
            background: $discord;

            &:hover,
            &:focus {
                background: darken($discord, 10%);
            }
        }
    }

    .video-background {
        background: url("../assets/images/aurora-poster.png") no-repeat;
        background-size: cover;
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}
