@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.player {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: rem-calc(1000);
    position: relative;
}

.player-container {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}