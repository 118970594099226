// Standard Variables
$root: 16 !default;

// Color Variables
$primary:   #ff2577 !default;
$secondary: #ABC798 !default;

$success:   #289663 !default;
$info:      #019AB9 !default;
$warning:   #E23B4B !default;
$danger:    #967001 !default;

$light:     #FDE8E9 !default;
$dark:      #1F2232 !default;
$gray:      #596475 !default;
$gray-dark: #14161a !default;

$white:     #fff !default;
$black:     #000 !default;

$twitch:    #6441a5 !default;
$discord:   #7289da !default;
$twitter:   #00acee !default;
$instagram: #e95950 !default;

$header-height: 84;

/* Standard Breakpoints */
$sm-min:  576px;
$md-min:  768px;
$lg-min:  1024px;
$xl-min:  1200px;
$xxl-min: 1400px;
$xxl-max: 1200;