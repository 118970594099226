@import './../assets/scss/includes/_variables.scss';
@import './../assets/scss/includes/_mixins.scss';
@import './../assets/scss/includes/_media-queries.scss';

.works-container {

    .works {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .work-type {
            background: lighten($gray-dark, 5%);
            margin-left: rem-calc(12);
            margin-right: rem-calc(12);
            overflow: hidden;
            padding: rem-calc(12) rem-calc(12) 0;
            width: calc(33.3333333% - rem-calc(24));

            &:first-of-type {
                margin-left: 0;
                width: calc(33.3333333% - rem-calc(12));
            }

            &:last-of-type {
                margin-right: 0;
                width: calc(33.3333333% - rem-calc(12));
            }

            .slide-container {
                margin-bottom: rem-calc(24);
            }
        }
    }

    .slide-container,
    div[dir="ltr"],
    .react-slideshow-container {
        height: 100%;
    }

    .each-slide {

        .video {
            object-fit: cover;
            width: 100%;
        }
    }

    .default-nav {
        background: none;

        &:hover,
        &:focus,
        &:active {
            background: none;

            svg {
                fill: $light;
                opacity: 1;
            }
        }

        svg {
            fill: $primary;
            transition: fill 0.5 ease;
        }
    }
}