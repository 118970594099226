@import './variables.scss';

// Font Settings
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

@mixin default-font ($weight: 'regular') {
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;

    @if $weight == 'light' {
        font-weight: 300;
    }
    @else if $weight == 'regular' {
        font-weight: 400;
    }
    @else if $weight == 'medium' {
        font-weight: 600
    }
    @else if $weight == 'bold' {
        font-weight: 700;
    }
    @else {
        font-weight: 400;
    }
}

@function rem-calc($value, $base: $root) {
    $remValue: calc($value / $base) + rem;	
    @return $remValue;
}